<template>
  <div>
    <el-button @click="getData">ceshi</el-button>
  </div>
</template>

<script>
export default {
  methods: {
    getData() {
      let me = this;
      console.log(this.axios)
      me.axios.all([me.test1(), me.test2()]).then(
        me.axios.spread(function(allTask, allCity) {
          //console.log("所有请求完成");
          //console.log("请求1结果", allTask);
          //console.log("请求2结果", allCity);
        })
      );
    },
    test1() {
      return this.axios.get("/stat/queryStudyPvTotal.service", {
        params: {
          startDate: "",
          endDate: "",
          hospitalCodeList: "12410600417905606H"
        }
      });
    },
    test2() {
      return this.axios.get("/stat/queryStudyIndexTop.service", {
        params: {
          page: {
            current: 1, // 当前页pageNum
            length: 30 // 每页显示条数pageSize
          },
          hospitalCodeList: "12410600417905606H"
        }
      });
    }
  }
};
</script>

